import React from "react"
import PageTransition from 'gatsby-plugin-page-transitions';
// import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import WhoAreWe from "../../components/who-are-we"
import Sponsores from "../../components/sponsores"

class MediaLibrary extends React.Component {
  render = () => (
    
        <Layout>
            <SEO title="Media library" keywords={[`gatsby`, `application`, `react`]} />
            <div className="hero hero--smallest hero--project-media-library inuit-box inuit-box--flush">
                <h2 className="hero__message hero__message--bigger hero__message--center">
                
                </h2>
            </div>
            <article className="article project inuit-u-p0">
                <div className="article__body article__body--has-extra">
                    <br />
                    <h1 className="article__heading">MEDIA LIBRARY.</h1>
                    <p className="article__para">
                        Digitalisation du processus d'archivage des fichiers produits par les agences creatives
                    </p>
                </div>

                <p className="article__belt inuit-u-mb0">
                    <img className="article__belt-image" src="/img/projects/media-library/Banner-architecture.jpg" alt="branding" />
                </p>
                
                <br /><br />
            </article>
            <Sponsores />
            <WhoAreWe />
        </Layout>
    
  )

}

export default MediaLibrary
